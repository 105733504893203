<template>
    <v-card>
        <v-card-title class="justify-center">
            <span class="text-h5 text-center"> {{ title }}</span>
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <div
                v-if="getImage"
                class="d-flex justify-center"
            >
                <v-img
                    :src="getImage"
                    width="100%"
                    height="200px"
                    contain
                />
            </div>
            <div>
                <span
                    class="ql-editor"
                    v-html="post.attributes.body"
                />
            </div>
        </v-card-text>

        <nextPostBtn :url-next-post="nextPost" />
    </v-card>

    <!--    <div> {{ post && post.attributes && post.attributes.body }} </div>-->
</template>

<script>

import { mapGetters } from 'vuex'
import postMixin from '@/mixins/postMixin'
import nextPostBtn from '@/components/posts/nextPostBtn'

export default {
    name: 'PostView',
    components: { nextPostBtn },
    mixins: [postMixin],
    data: () => ({}),
    computed: {
        title() {
            const attributes = this.post.attributes
            return attributes.title2 ?? attributes.title
        },

        getImage() {
            if (this.post.attributes.image2) {
                return process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=posts/images/' + this.post.attributes.image2
            }

            return ''
        }
    },
    async created() {

    },
    methods: {}

}
</script>

<style lang="css" scoped>
/*@import "~vue2-editor/dist/vue2-editor.css";*/

/* Import the Quill styles you want */
/*@import '~quill/dist/quill.core.css';*/
/*@import '~quill/dist/quill.bubble.css';*/

/*@import '~quill/dist/quill.snow.css';*/
</style>
